$(function() {
    if (isMobile) {
        $('.text-image').each(function() {
            var $this = $(this);

            // move image element to image-placeholder
            var $image = $this.find('.image-hover');
            var $placeholder = $this.find('.image-placeholder');
            $image.appendTo($placeholder);
        });
    }
});
