if (isMobile) {
    var bar18 = $('.bar-18');
    var bar18Height = bar18.outerHeight();
    var mobileActions = $('.mobile-actions');

    if (typeof ScrollMagic !== 'undefined') {
        var controller = new ScrollMagic.Controller();
        var toggleTop = new ScrollMagic.Scene({ offset: 100 })
            .on('enter', function() {
                mobileActions.css({
                    opacity: 1,
                    transform: 'translateY(-' + bar18Height + 'px)',
                });
            })
            .on('leave', function() {
                mobileActions.css({
                    opacity: 0,
                    transform: 'translateY(100%)',
                });
            })

            .addTo(controller);
    }

    $('.to-top').on('click', function(e) {
        e.preventDefault();
        $('body,html').animate(
            {
                scrollTop: 0,
            },
            1000
        );
    });
}
