$(function() {
    var carouselWidth = $('main .content').outerWidth() / 2 - 15;

    $('.carousel').each(function() {
        var $this = $(this);
        var swiperContainer = $this.find('.swiper-container');

        var options = {
            speed: 400,
            spaceBetween: 0,
            slidesPerView: 1,
            loop: true,
            width: carouselWidth,
            inverse: true,
            loopAdditionalSlides: 2,
            grabCursor: true,
            navigation: {
                nextEl: $this.find('.swiper-next'),
            },
            breakpoints: {
                // when window width is <= 767px
                767: {
                    width: $('main .content').outerWidth(),
                },
            },
        };

        var mySwiper = new Swiper(swiperContainer, options);
    });
});
