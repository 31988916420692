$(function() {
    if ($.validator) {
        $.validator.addMethod(
            'greaterThanZero',
            function(value, element) {
                return this.optional(element) || parseFloat(value) > 0;
            },
            'Vul hier een geldig huisnummer in.'
        );

        $.validator.addMethod(
            'zipcode',
            function(value) {
                return /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(value);
            },
            'Vul hier een geldige postcode in.'
        );

        $.validator.addMethod(
            'phone',
            function(value) {
                if (/^[\+\d]?(?:[\d-.\s()]*)$/.test(value)) {
                    return true;
                } else {
                    return false;
                }
            },
            'Vul hier een geldig telefoonnummer in.'
        );

        $.validator.addMethod(
            'filesize',
            function(value, element, param) {
                return this.optional(element) || element.files[0].size <= param;
            },
            'Bestandsgrootte mag maximaal 5 MB zijn.'
        );

        // Accept a value from a file input based on a required mimetype
        $.validator.addMethod(
            'accept',
            function(value, element, param) {
                var typeParam = 'image/*';

                // Split mime on commas in case we have multiple types we can accept
                if (typeof param === 'string') {
                    typeParam = param.replace(/\s/g, '');
                }

                var optionalValue = this.optional(element),
                    i,
                    file,
                    regex;

                // Element is optional
                if (optionalValue) {
                    return optionalValue;
                }

                if ($(element).attr('type') === 'file') {
                    // Escape string to be used in the regex
                    // see: https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
                    // Escape also "/*" as "/.*" as a wildcard
                    typeParam = typeParam
                        .replace(/[\-\[\]\/\{\}\(\)\+\?\.\\\^\$\|]/g, '\\$&')
                        .replace(/,/g, '|')
                        .replace(/\/\*/g, '/.*');

                    // Check if the element has a FileList before checking each file
                    if (element.files && element.files.length) {
                        regex = new RegExp('.?(' + typeParam + ')$', 'i');
                        for (i = 0; i < element.files.length; i++) {
                            file = element.files[i];

                            // Grab the mimetype from the loaded file, verify it matches
                            if (!file.type.match(regex)) {
                                return false;
                            }
                        }
                    }
                }

                // Either return true because we've validated each file, or because the
                // browser does not support element.files and the FileList feature
                return true;
            },
            'Kies een afbeelding van het type JPG of PNG.'
        );
    }
});
