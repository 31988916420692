$(function() {
    $('.toggle-searchform').on('click', function() {
        var $searchBar = $('.search-bar');
        var $form = $searchBar.find('form');
        var $input = $form.find('input');

        // search parameter filled? then do submit
        if ($input.val() !== '') {
            $form.submit();
        }

        // else toggle form
        else {
            $searchBar.toggleClass('expanded');
            if ($searchBar.hasClass('expanded')) {
                $input.focus();
            } else {
                $input.blur();
            }
        }
    });

    // $('.search-bar form input').on('blur', function() {
    //     $('.search-bar').toggleClass('expanded');
    // });
});
