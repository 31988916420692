$(function() {
    var productCode = 'PeroniNastroAzzurro2019';
    var googleMapsKey = 'AIzaSyC01VI-aK1yqVNwO_SvTsrGuNlK_kcHwwU';

    var _map = {
        elClass: 'map',
        map: null,
        defaultLocation: null,
        zoom: 8,
        zoomClose: 14,
        markers: [],
        markerClusterer: null,
        infoWindow: null,

        init: function() {
            var mod = _map;
            mod.map = $('.' + mod.elClass);

            if (mod.map.length > 0) {
                mod.startMap();
            }
        },

        startMap: function() {
            var mod = _map;

            $.getScript('https://maps.google.com/maps/api/js?key=' + googleMapsKey + '&libraries=places')
                .done(function(script, textStatus) {
                    // Google map script loaded successfully
                    mod.initBarMap();
                })
                .fail(function(jqxhr, settings, ex) {
                    // Could not load Google Map script
                    alert('Fout: Google Maps script kon niet worden geladen!');
                });
        },

        handleLocationError: function(browserHasGeolocation, infowindow, pos) {
            console.log('The Geolocation service failed');
            // hide geo button
            $('.btn.geo').hide();
        },

        html5Geocode: function() {
            var mod = _map;

            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        var pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        mod.map.setCenter(pos);
                        mod.map.setZoom(mod.zoomClose);
                    },
                    function() {
                        mod.handleLocationError(true, mod.infoWindow, mod.map.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                mod.handleLocationError(false, mod.infoWindow, mod.map.getCenter());
            }
        },

        geocodeAddress: function(geocoder, resultsMap) {
            var address = document.getElementById('address').value;

            if (address !== '') {
                geocoder.geocode({ address: address }, function(results, status) {
                    if (status === 'OK') {
                        resultsMap.setCenter(results[0].geometry.location);
                    } else {
                        alert('De ingevoerde plaats kon niet worden opgehaald!');
                    }
                });
            }
        },

        showMarkers: function() {
            var mod = _map;
            mod.markers = [];

            // Load markerClusterer script
            $.getScript(
                metaData.contextPath + '/.resources/themes/grolsch-business/dist/vendors/markerclusterer.min.js'
            )
                .done(function(script, textStatus) {
                    // markerClusterer script loaded successfully
                })
                .fail(function(jqxhr, settings, ex) {
                    // Could not load Google Map script
                    console.log('Markerclusterer script kon niet worden geladen.');
                });

            if (mod.markerClusterer) {
                mod.markerClusterer.clearMarkers();
            }

            var markerIcon = {
                path:
                    'M66.9,41.8c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4c0,11.3,20.4,32.4,20.4,32.4S66.9,53.1,66.9,41.8z M37,41.4c0-5.2,4.3-9.5,9.5-9.5c5.2,0,9.5,4.2,9.5,9.5c0,5.2-4.2,9.5-9.5,9.5C41.3,50.9,37,46.6,37,41.4z',
                fillColor: '#023e84',
                fillOpacity: 1,
                anchor: new google.maps.Point(42, 67),
                strokeWeight: 0,
                scale: 0.4,
            };

            // Fetch bars
            $.ajax({
                type: 'GET',
                url: metaData.contextPath + '/.rest/business/v1/barfinder/' + productCode,
                dataType: 'json',
                contentType: 'application/json',
                success: function(data) {
                    var bars = data;

                    for (var i = 0; i < data.items.length; i++) {
                        var bar = data.items[i];
                        if (bar.latitude && bar.longitude) {
                            var latLng = new google.maps.LatLng(bar.latitude, bar.longitude);

                            if (mod.markers.length != 0) {
                                // Check for duplicate positions, meaning multiple bars in the same location
                                // Add minor position change, so markerClusterer doesn't cluster these bars

                                for (i = 0; i < mod.markers.length; i++) {
                                    var existingMarker = mod.markers[i];
                                    var pos = existingMarker.getPosition();
                                    if (latLng.equals(pos)) {
                                        var a = 360.0 / mod.markers.length;
                                        var newLat = pos.lat() + -0.00003 * Math.cos(((+a * i) / 180) * Math.PI); //x
                                        var newLng = pos.lng() + -0.00003 * Math.sin(((+a * i) / 180) * Math.PI); //Y
                                        latLng = new google.maps.LatLng(newLat, newLng);
                                    }
                                }
                            }

                            var marker = new google.maps.Marker({
                                position: latLng,
                                icon: markerIcon,
                            });

                            var fn = mod.markerClickFunction(bar, latLng);
                            google.maps.event.addListener(marker, 'click', fn);
                            mod.markers.push(marker);
                        }
                    }

                    window.setTimeout(function() {
                        // mod.markerCluster = new MarkerClusterer(mod.map, mod.markers, {
                        //     imagePath: metaData.contextPath + '/.resources/themes/grolsch-business/dist/img/markers/m',
                        // });
                        mod.markerCluster = new MarkerClusterer(mod.map, mod.markers, {
                            styles: [
                                {
                                    url: metaData.contextPath + '/.resources/themes/peroni/dist/img/markers/m1.png',
                                    height: 52,
                                    width: 53,
                                    textColor: '#ffffff',
                                    textSize: 13,
                                },
                                {
                                    url: metaData.contextPath + '/.resources/themes/peroni/dist/img/markers/m2.png',
                                    height: 55,
                                    width: 56,
                                    textColor: '#ffffff',
                                    textSize: 13,
                                },
                                {
                                    url: metaData.contextPath + '/.resources/themes/peroni/dist/img/markers/m3.png',
                                    height: 65,
                                    width: 66,
                                    textColor: '#ffffff',
                                    textSize: 13,
                                },
                                {
                                    url: metaData.contextPath + '/.resources/themes/peroni/dist/img/markers/m4.png',
                                    height: 77,
                                    width: 78,
                                    textColor: '#ffffff',
                                    textSize: 13,
                                },
                                {
                                    url: metaData.contextPath + '/.resources/themes/peroni/dist/img/markers/m5.png',
                                    height: 89,
                                    width: 90,
                                    textColor: '#ffffff',
                                    textSize: 13,
                                },
                            ],

                            // imagePath: metaData.contextPath + '/.resources/themes/grolsch-business/dist/img/markers/m',
                        });
                    }, 0);
                },
                fail: function() {},
            });
        },

        markerClickFunction: function(bar, latLng) {
            return function(e) {
                var mod = _map;

                e.cancelBubble = true;
                e.returnValue = false;
                if (e.stopPropagation) {
                    e.stopPropagation();
                    e.preventDefault();
                }

                var infoHtml =
                    '<div class="infowindow">' +
                    '<strong>' +
                    bar.outlet_name +
                    '</strong><br />' +
                    bar.outlet_address +
                    '<br />' +
                    bar.outlet_zipcode +
                    ' ' +
                    bar.outlet_city +
                    '<br /><br /><a href="https://maps.google.com/maps?q=loc:' +
                    bar.latitude +
                    ',' +
                    bar.longitude +
                    '" target="_blank">Route</a>' +
                    '</div>';

                mod.infoWindow.setContent(infoHtml);
                mod.infoWindow.setPosition(latLng);
                mod.infoWindow.open(mod.map);
            };
        },

        initBarMap: function() {
            var mod = _map;
            mod.infoWindow = new google.maps.InfoWindow();

            mod.defaultLocation = new google.maps.LatLng(52.2401870486056, 5.52045879829099);

            mod.map = new google.maps.Map(document.getElementsByClassName(mod.elClass)[0], {
                center: mod.defaultLocation,
                zoom: mod.zoom,
                disableDefaultUI: true,
                zoomControl: true,
                styles: mapStyles,
            });

            mod.html5Geocode();

            mod.showMarkers();

            var geocoder = new google.maps.Geocoder();
            document.getElementById('bar-locator').addEventListener('submit', function() {
                mod.geocodeAddress(geocoder, mod.map);
                mod.map.setZoom(14);
            });

            $('#bar-locator .geo').on('click', function() {
                mod.html5Geocode();
            });
        },
    };

    _map.init();
});
