$(function() {
    $('a[href^="#"]').on('click', function(e) {
        e.preventDefault();

        var el = $('[name="' + $.attr(this, 'href').substr(1) + '"]');

        if (el.length > 0) {
            $('html, body').animate(
                {
                    scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top,
                },
                500
            );
        }
        return false;
    });
});
