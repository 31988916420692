$(document).ready(function() {
    $('.grid').isotope({
        itemSelector: '.grid-item',
        percentPosition: true,
        masonry: {
            columnWidth: '.grid-sizer',
            gutter: 30,
        },
    });

    // Only animate when not in editor mode
    if (!$('#main').hasClass('magnolia-editor')) {
        var controller = new ScrollMagic.Controller();

        $('.grid-item').each(function() {
            var $this = $(this);

            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 'onEnter',
                offset: 100,
                reverse: false,
            })
                .setTween(
                    TweenMax.to($this, 0.1, {
                        opacity: 1,
                        scale: 1,
                        ease: Circ.easeOut,
                    })
                )
                //.addIndicators()
                .addTo(controller);
        });
    }
});
