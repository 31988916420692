function playVideo(e) {
    // Get video image cover
    var videoImageCover = e.currentTarget.parentElement;

    // Get iframe
    var iFrame = e.currentTarget.parentElement.nextElementSibling;

    // Hide video image cover
    videoImageCover.classList.add('hidden');

    // Show video
    iFrame.classList.remove('hidden');

    // Start playing the video
    if (iFrame.src.includes('?')) {
        iFrame.src += '&autoplay=1';
    } else {
        iFrame.src += '?autoplay=1';
    }
}
