// The video
var video = document.getElementById('commercial');
if (video) {
    video.pause();
}

// Check if the entered date is in fact a valid and existing date
function isValidDate(y, m, d) {
    if (y.toString().length == 4) {
        var date = new Date(y, m - 1, d);

        if (Object.prototype.toString.call(date) === '[object Date]') {
            if (isNaN(date.getTime())) {
                return false;
            } else {
                var convertedDate = '' + date.getFullYear() + (date.getMonth() + 1) + date.getDate();
                var givenDate = '' + y + m + d;
                return givenDate == convertedDate;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// Setting cookie, checking checkbox
function setCookieAndRedirect() {
    var remember = document.getElementById('remember').checked;
    var expires = '';
    if (remember) {
        var d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        expires = '; expires=' + d.toUTCString();
    }
    var url = window.location.href;
    if (url.indexOf(':8080') < 0 && url.indexOf(':8081') < 0) {
        document.cookie = 'peroni-age-verification=allowed;path=/;samesite=none;secure' + expires;
    } else {
        document.cookie = 'peroni-age-verification=allowed;path=/' + expires;
    }
    if (url.indexOf('?') < 0) {
        document.body.className = document.body.className.replace('gated', '');
        window.scrollTo(0, 0);
    }

    var redirectUrl = getRedirectUrl();

    if (redirectUrl.length > 0) {
        window.location.href = redirectUrl;
    }

    document.body.className = document.body.className.replace('gated', '');
    window.scrollTo(0, 0);
}

function getRedirectUrl() {
    var url = window.location.href;
    var redirectUrl = '';
    var splitUrl = url.split('?');
    var params = '';
    if (splitUrl.length > 1) {
        params = splitUrl[1].split('&');
    } else {
        redirectUrl = '';
    }

    var querystrings = '';

    if (url.split('?')[2] !== undefined) {
        querystrings = url.split('?')[2].split('&');
    }

    for (var i in params) {
        var param = params[i].split('=');
        if (param[0] !== 'returnUri') {
            continue;
        }

        var returnUri = param[1];
        redirectUrl += returnUri;

        // Only handle external links to Grolsch.nl Peroni.nl and viperhardseltzer.nl
        if (
            returnUri.indexOf('http') === 0 &&
            (returnUri.indexOf('peroni.nl') > 0 || returnUri.indexOf('viperhardseltzer.nl') > 0)
        ) {
            redirectUrl = returnUri;
        }
    }

    if (querystrings.length > 0) {
        redirectUrl += '?';
        for (var j in querystrings) {
            redirectUrl += querystrings[j];
            if (j < querystrings.length - 1) {
                redirectUrl += '&';
            }
        }
    }

    return redirectUrl;
}

// checks if userAgent is probably a bot
function isBot(userAgent) {
    var pattern = RegExp(/bot|google|baidu|bing|msn|teoma|slurp|yandex/i);
    return userAgent.match(pattern);
}

// Checking cookie
function checkCookie() {
    if (isBot(window.navigator.userAgent)) {
        return;
    }
    window.scrollTo(0, 0);
    var pattern = RegExp('peroni-age-verification=.[^;]*');
    var matched = document.cookie.match(pattern);
    if (matched) {
        var cookie = matched[0].split('=');
        if (cookie[1] !== 'allowed') {
            if (document.querySelector('.agegate') !== null) {
                document.body.className += ' ' + 'gated';
            }
        } else {
            var redirectUrl = getRedirectUrl();
            if (redirectUrl.length > 0) {
                window.location.href = redirectUrl;
            }
        }
    } else {
        if (document.querySelector('.agegate') !== null) {
            document.body.className += ' ' + 'gated';
        }
    }
}

//Check if the age of the given date is >= 18 years
//Set cookie if old enough
function validAge(date) {
    var allowedDate = new Date();
    allowedDate.setHours(0, 0, 0, 0);
    allowedDate.setFullYear(allowedDate.getFullYear() - 18);

    $('.error')
        .html('')
        .removeClass('active');

    if (date <= allowedDate) {
        setCookieAndRedirect();
    } else {
        var form = document.getElementsByClassName('check-age')[0];
        if (form !== null) {
            window.location.href = form.getAttribute('data-invalid-url');
        }
    }
}

checkCookie();

(function() {
    var ageCheckButton = document.getElementById('ageCheck');
    if (ageCheckButton !== null) {
        ageCheckButton.onclick = function(e) {
            e.preventDefault();

            var day = parseInt(document.getElementById('day').value);
            var month = parseInt(document.getElementById('month').value);
            var year = parseInt(document.getElementById('year').value);

            var birthdate = new Date(year, month - 1, day); //Month -1 because month starts with 0

            if (isValidDate(year, month, day)) {
                validAge(birthdate);
            } else {
                $('.error')
                    .html('Ongeldige geboortedatum!')
                    .addClass('active');
            }
        };
    }
})();

$(function() {
    $('.check-age input').keyup(function(event) {
        var newValue = $(this).val();
        //see if the textbox had its value changed
        if ($(this).attr('prevValue') !== newValue) {
            //see if the number of entered characters is equal or greater
            //than the allowable maxlength
            if (newValue.length >= $(this).attr('maxlength')) {
                //set focus on the next field with autotab class

                $(this)
                    .parent()
                    .next('label')
                    .find('input')
                    .focus();
            }
            //save newly entered value for the next execution of this event
            $(this).attr('prevValue', newValue);
        }
    });
});
