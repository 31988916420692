function TailorMadeCampaign(campaignSettings) {
    this.debug = false;
    this.campaignId = campaignSettings.campaignId;
    this.campaignType = campaignSettings.campaignType;
    this.campaignCode = campaignSettings.campaignCode;
    this.brandCode = 'peroni';
    this.sfEventKey = campaignSettings.sfEventKey;
    this.campaignComponent = document.querySelector('.tailor-made-campaign-component');
    this.campaignScreenMainElem = document.querySelector('.campaign-screen-main');

    this.campaignStoreSelector = this.campaignScreenMainElem.querySelector('#store');
    this.criticalErrorElem = this.campaignScreenMainElem.querySelector('.critical-error');
    this.termsCheckboxElem = this.campaignScreenMainElem.querySelector('[name="termsAndConditions"]');
    this.campaignScreenTwoButton = document.querySelector('.send');
    this.campaignScreenWin = document.querySelector('.campaign-screen-win');
    this.allInputFields = document.querySelectorAll('.campaign-component input, .campaign-component textarea');
    this.imagePlaceholderWrapper = document.querySelector('.image-placeholder-wrapper');
    this.dropRegion = document.querySelector('.drop-region');
    this.imagePreviewWrapper = document.getElementById('image-preview-wrapper');
    this.fakeInput = document.createElement('input');
    this.populateFakeInput(
        this.fakeInput,
        this.dropRegion,
        this.imagePreviewWrapper,
        this.criticalErrorElem,
        this.imagePlaceholderWrapper
    );
    this.files = [];

    this.hasPersonalDataBeenSend = false;
    this.addEventListeners();
    this.getStores();
}

TailorMadeCampaign.prototype.populateFakeInput = function(
    fakeInput,
    dropRegion,
    imagePreviewWrapper,
    criticalErrorElem,
    imagePlaceholderWrapper
) {
    fakeInput.style = 'display:none;';
    dropRegion.appendChild(fakeInput);
    fakeInput.type = 'file';
    fakeInput.accept = 'image/*';
    fakeInput.multiple = false;
    fakeInput.setAttribute('required', 'required');
    fakeInput.id = 'file-upload';
    fakeInput.setAttribute('class', 'placeholder'); //TODO Wat voor verzinnen
    this.dropRegion.addEventListener('click', function() {
        fakeInput.click();
    });
    this.fakeInput.addEventListener('change', function() {
        if (criticalErrorElem.classList.contains('image-error')) {
            criticalErrorElem.classList.remove('image-error');
            criticalErrorElem.style.display = 'none';
        }

        imagePreviewWrapper.innerHTML = '';
        if (fakeInput.files.length > 0) {
            if (fakeInput.files[0].size < 52428810) {
                this.files = fakeInput.files;
                // Image must not exceed 50MB (52428800 bytes)
                var img = document.createElement('img');
                img.id = 'img-preview';
                imagePreviewWrapper.appendChild(img);
                img.src = URL.createObjectURL(this.files[0]);
                imagePlaceholderWrapper.style.display = 'none';
            } else {
                fakeInput.value = '';
                criticalErrorElem.style.display = 'block';
                criticalErrorElem.classList.add('image-error');
                criticalErrorElem.querySelector('.error').innerHTML =
                    'De grootte van de foto mag niet meer zijn dan 5MB';
                imagePlaceholderWrapper.style.display = 'block';
            }
        } else {
            criticalErrorElem.style.display = 'block';
            criticalErrorElem.classList.add('image-error');
            criticalErrorElem.querySelector('.error').innerHTML = 'Er ging iets fout met het ophalen van de foto';
            imagePlaceholderWrapper.style.display = 'block';
        }
    });
};

TailorMadeCampaign.prototype.addEventListeners = function() {
    this.campaignScreenTwoButton.addEventListener('click', this.campaignScreenMainButtonHandler.bind(this));

    for (var i = 0; i < this.allInputFields.length; i++) {
        this.allInputFields[i].addEventListener('input', this.inputChangeHandler.bind(this));
    }
};

TailorMadeCampaign.prototype.scrollToEl = function(element) {
    var top = 0,
        left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    window.scrollTo({
        top: top - 30,
        left: 0,
        behavior: 'smooth',
    });
};

TailorMadeCampaign.prototype.campaignScreenMainButtonHandler = function(event) {
    event.preventDefault();
    if (this.isInputValid() && !this.hasPersonalDataBeenSend) {
        if (this.debug) {
            // this.showLoseScreen();
            this.showWinScreen();
        } else {
            this.sendPersonalData();
            this.hasPersonalDataBeenSend = true;
        }
    } else {
        var firstError = document.querySelectorAll(
            '.form-control.error, .form-checkbox.error, .critical-error.terms-error'
        )[0];
        if (firstError) {
            this.scrollToEl(firstError);
        }
    }
};

TailorMadeCampaign.prototype.inputChangeHandler = function(event) {
    if (event.target.classList.contains('error')) this.isInputValid();
};

TailorMadeCampaign.prototype.getStores = function() {
    if (this.campaignStoreSelector) {
        var storeSelector = this.campaignStoreSelector;
        $.ajax({
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            url: _mgnl.contextPath + '/.rest/wincode/v1/stores/' + this.campaignId,
            success: function(response) {
                for (var store in response) {
                    var el = response[store];
                    var opt = document.createElement('option');
                    opt.value = el.id;
                    opt.innerHTML = el.name;
                    storeSelector.appendChild(opt);
                }
                if (response.length === 1) {
                    storeSelector.selectedIndex = 1;
                    storeSelector.disabled = 'disabled';
                }
            },
        });
    }
};

TailorMadeCampaign.prototype.sendPersonalData = function() {
    var person = {
        email: this.getInputFieldsOnCurrentScreenByName('email').value,
        receiveNewsletter: this.getInputFieldsOnCurrentScreenByName('receiveNewsletter').checked,
    };

    person.firstName = this.getInputFieldsOnCurrentScreenByName('firstName').value;
    person.lastName = this.getInputFieldsOnCurrentScreenByName('lastName').value;

    var consentPartner = false;
    if (this.getInputFieldsOnCurrentScreenByName('consentPartner')) {
        consentPartner = this.getInputFieldsOnCurrentScreenByName('consentPartner').checked;
    }

    var formData = new FormData();
    formData.append('campaignId', this.campaignId);
    formData.append('campaignType', this.campaignType);
    formData.append('sfEventKey', this.sfEventKey);
    formData.append('brandCode', this.brandCode);
    formData.append('image', this.fakeInput.files[0]);
    formData.append('person', JSON.stringify(person));
    formData.append('code', this.campaignCode);
    formData.append('shopId', this.getInputFieldsOnCurrentScreenByName('store').value);
    formData.append('slogan', this.getInputFieldsOnCurrentScreenByName('slogan').value);
    formData.append('consentPartner', consentPartner);
    $.ajax({
        type: 'POST',
        url: _mgnl.contextPath + '/.rest/wincode/v1/redeemreceipt',
        data: formData,
        processData: false,
        contentType: false,
        success: function(response) {
            if (response.error) {
                this.criticalErrorElem.style.display = 'block';
                this.hasPersonalDataBeenSend = false;
                this.criticalErrorElem.querySelector('.error').innerHTML = response.error;

                this.scrollToEl(this.criticalErrorElem);
            } else if (response.hasWon) {
                this.showWinScreen();

                this.contactInformationButton.setAttribute(
                    'href',
                    this.contactInformationButton.dataset.link + this.code
                );
            }
        }.bind(this),
        error: function() {
            this.criticalErrorElem.style.display = 'block';
            this.hasPersonalDataBeenSend = false;
            this.criticalErrorElem.querySelector('.error').innerHTML =
                'Er is iets fout gegaan, probeer het later opnieuw';
        }.bind(this),
    });
};

TailorMadeCampaign.prototype.showWinScreen = function() {
    this.campaignScreenMainElem.classList.remove('showing');
    this.campaignScreenWin.classList.add('showing');
    this.scrollToEl(this.campaignComponent);
};

TailorMadeCampaign.prototype.getInputFieldsOnCurrentScreen = function() {
    return document.querySelectorAll(
        '.campaign-screen.showing input, ' +
            '.campaign-screen.showing textarea, ' +
            '.campaign-screen.showing select, ' +
            '.campaign-screen.showing file'
    );
};

TailorMadeCampaign.prototype.getInputFieldsOnCurrentScreenByName = function(name) {
    var inputFields = this.getInputFieldsOnCurrentScreen();

    for (var i = 0; i < inputFields.length; i++) {
        if (inputFields[i].getAttribute('name') === name) {
            return inputFields[i];
        }
    }
};

TailorMadeCampaign.prototype.isInputValid = function() {
    var inputFields = this.getInputFieldsOnCurrentScreen();
    var numberOfErrors = 0;

    for (var i = 0; i < inputFields.length; i++) {
        var inputField = inputFields[i];
        var mailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.hideError(inputField);

        if (inputField.hasAttribute('required')) {
            if (inputField.value) {
                switch (inputField.getAttribute('type')) {
                    case 'email':
                        if (!mailRe.test(String(inputField.value).toLowerCase())) {
                            numberOfErrors++;
                            this.showError(inputField, 'Voer een geldig e-mailadres in');
                        }
                        continue;
                    case 'checkbox':
                        if (!inputField.checked) {
                            numberOfErrors++;

                            if (inputField.name !== this.termsCheckboxElem.name) {
                                this.showError(inputField);
                            } else {
                                this.criticalErrorElem.style.display = 'block';
                                this.criticalErrorElem.classList.add('terms-error');
                                this.criticalErrorElem.querySelector('.error').innerHTML =
                                    'Je bent nog niet akkoord gegaan met de actievoorwaarden.';
                            }
                        } else {
                            if (this.criticalErrorElem.classList.contains('terms-error')) {
                                this.criticalErrorElem.classList.remove('terms-error');
                                this.criticalErrorElem.style.display = 'none';
                            }
                        }
                }
            } else {
                numberOfErrors++;
                this.showError(inputField);
            }
        }
    }
    return numberOfErrors === 0;
};

TailorMadeCampaign.prototype.showError = function(inputField, errorMessage) {
    var inputFieldId = inputField.getAttribute('id');
    var label = document.querySelector('[for=' + inputFieldId + ']');
    var errorLabel = inputField.parentElement.querySelector('.error-message');

    switch (inputField.getAttribute('type')) {
        case 'checkbox':
            inputField.classList.add('error');
            label.classList.add('error-checkbox');
            break;
        case 'file':
            this.dropRegion.classList.add('error');
            this.dropRegion.parentElement.querySelector('.error-message').classList.add('showing');
            this.dropRegion.parentElement.querySelector('.error-message').innerText =
                errorMessage || 'Dit is een verplicht veld';
            break;
        default:
            inputField.classList.add('error');

            if (errorLabel) {
                errorLabel.innerText = errorMessage || 'Dit is een verplicht veld';
                errorLabel.classList.add('showing');
            } else {
                label.innerText = errorMessage || 'Dit is een verplicht veld';
                label.classList.add('showing');
            }
    }
};

TailorMadeCampaign.prototype.hideError = function(inputField) {
    var inputFieldId = inputField.getAttribute('id');
    var label = document.querySelector('[for=' + inputFieldId + ']');
    var errorLabel = inputField.parentElement.querySelector('.error-message');

    switch (inputField.getAttribute('type')) {
        case 'checkbox':
            inputField.classList.remove('error');
            label.classList.remove('error-checkbox');
            break;
        case 'file':
            this.dropRegion.classList.remove('error');
            this.dropRegion.parentElement.querySelector('.error-message').classList.remove('showing');
            break;
        default:
            inputField.classList.remove('error');
            if (errorLabel) {
                errorLabel.classList.remove('showing');
            } else {
                label.classList.remove('showing');
            }
    }
};

if (document.querySelector('.tailor-made-campaign-component')) {
    var campaign = new TailorMadeCampaign(campaignSettings);
}
