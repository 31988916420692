$(function() {
    var controller = new ScrollMagic.Controller();

    // Only animate when not in editor mode
    if (!$('#main').hasClass('magnolia-editor')) {
        $('section').each(function() {
            var $section = $(this);
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 'onEnter',
                offset: 250,
                reverse: false,
            })
                .setTween(
                    TweenMax.to($section, 0.4, {
                        opacity: 1,
                        y: 0,
                        ease: Power1.easeOut,
                    })
                )
                //.addIndicators()
                .addTo(controller);
        });
    }
});
