$(function() {
    // Handle newsletter component, submit emailadres
    var $newsletterForm = $('#newsletterSubscriptionForm');
    var $message = $newsletterForm.find('.message');
    var $newsletterFormHeading = $('.newsletter-form > .newsletter-form-heading');

    if ($newsletterForm.length > 0) {
        $newsletterForm.validate({
            errorElement: 'div',
            errorPlacement: function(error, element) {
                error.appendTo(element.parent());
            },
        });

        $newsletterForm.find('.btn').on('click', function(e) {
            e.preventDefault();
            var b = $(this);

            if ($newsletterForm.valid()) {
                $newsletterForm.addClass('processing');

                b.prop('disabled', true);

                //Get form url
                var url = $newsletterForm.attr('action');
                $newsletterForm.find('.newsletter-message').remove();
                var messageSuccess = $newsletterForm.data('submit-success');
                var messageError = $newsletterForm.data('submit-error');

                var formData = JSON.stringify({
                    spamProtection: $newsletterForm.find('input[name="spamProtection"]').val(),
                    email: $newsletterForm.find('input[name="email"]').val(),
                });

                var jqxhr = $.ajax({
                    type: 'POST',
                    url: url,
                    data: formData,
                    dataType: 'json',
                    contentType: 'application/json',
                });

                // Handle response
                jqxhr.always(function(data, status) {
                    if (status !== 'success') {
                        $message.addClass('error');
                        $message.html(messageError);
                    } else {
                        $newsletterForm.find('input[name="email"]').val('');
                        $newsletterForm.find('input[name="cb2"]').prop('checked', false);
                        $newsletterFormHeading.hide();
                    }

                    $message.show();

                    b.prop('disabled', false);
                    $newsletterForm.removeClass('processing').addClass('done');
                });
            }
        });
    }
});
